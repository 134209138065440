export default ({ API }) => {
  return {
    ...API.wrapAggregate({
      getCategories() {
        return this.get('/categories');
      },
    }),

    ...API.wrap({
      getCategoryBrand(categoryId) {
        return this.get(`/categories/${categoryId}/brands`);
      },
    }),
  };
};
