import debounce from 'debounce-promise';
import CartClient from '../apis/cart';
import ProductsClient from '../apis/products';

const SHOW_NEW_ENTRY_DURATION = 5000;

export default context => {
  const { BROWSER_ID, IDENTITY } = context;
  const cartClient = CartClient(context);
  const productsClient = ProductsClient(context);

  function trackAddToCart(product, quantity) {
    try {
      window.externalTracker.track.addToCart({ product, quantity });
    } catch (e) {
      console.warn(e.message);
    }
  }

  async function performActionOnCart(
    action,
    { state, commit, dispatch },
    { itemId, quantity, product, query = {}, headers, ...otherPayload },
  ) {
    if (!itemId && !product)
      throw new Error('one of itemId or product is required');

    let itemIdReal = itemId;

    if (!itemIdReal) {
      const item =
        state.items.find(
          o =>
            o.stuff && o.stuff.product_id && o.stuff.product_id === product.id,
        ) || {};
      itemIdReal = item.id;
    }

    if (!itemIdReal) {
      throw new Error('invalid itemId or product');
    }

    const res = await cartClient[action]({
      itemId: itemIdReal,
      quantity,
      query,
      headers,
      ...otherPayload,
    });

    if (action === 'updateProductInCart') {
      trackAddToCart(product, quantity);
    }

    if (!(res.meta.http_status < 300 && (res.data || res.message))) {
      throw new Error('something went wrong');
    }

    await dispatch('getCarts');

    if (action === 'updateProductInCart') {
      commit('pushNewCartEntries', { quantity, product });
      dispatch('clearNewCartEntriesDebounced');
    } else if (action === 'deleteProductFromCart') {
      commit('deleteFromNewCartEntries', { product });
    }

    return res;
  }

  function itemType(cartItem) {
    if (cartItem.stuff) {
      return cartItem.stuff.reference_type.split('_')[1];
    }

    return '';
  }

  function getProductId(cartItem) {
    if (itemType(cartItem) === 'sku') {
      return cartItem.stuff.product_id;
    }

    if (itemType(cartItem) === 'bundle') {
      return cartItem.stuff.units.map(unit => unit.product_id);
    }

    return 0;
  }

  function itemInactive(cartItem, products) {
    if (itemType(cartItem) === 'sku') {
      const itemProduct = products[getProductId(cartItem)];

      if (itemProduct) {
        return itemProduct.for_sale !== true;
      }

      return cartItem.stuff.state === 'inactive';
    }

    if (itemType(cartItem) === 'bundle') {
      return cartItem.stuff.units.some(unit => {
        const unitProduct = products[unit.product_id];

        if (unitProduct) {
          return unitProduct.for_sale !== true;
        }

        return unit.state === 'inactive';
      });
    }

    return false;
  }

  function itemOutOfStock(cartItem, products) {
    if (itemType(cartItem) === 'sku') {
      const itemProduct = products[getProductId(cartItem)];

      if (itemProduct) {
        return itemProduct.stock === 0;
      }

      return cartItem.stuff.stock === 0;
    }

    if (itemType(cartItem) === 'bundle') {
      return cartItem.stuff.units.some(unit => {
        const unitProduct = products[unit.product_id];

        if (unitProduct) {
          return unitProduct.stock === 0;
        }

        return unit.stock === 0;
      });
    }

    return false;
  }

  return {
    namespaced: true,
    state: {
      id: null,
      identity: BROWSER_ID || IDENTITY,
      userId: null,
      items: [],
      newCartEntries: [],
      clearNewCartEntriesDebounced: null,
      addOrUpdateTrain: {},
      isUpdated: false,
      updatedProduct: { id: '', quantity: 0 },
      isReady: false,
      cartsPromise: null,
      cartDialogueShown: false,
      products: {},
    },
    getters: {
      items(state) {
        return state.items;
      },
      totalItems(state) {
        return state.items.length;
      },
      totalQuantity(state) {
        let totalQuantity = 0;
        state.items.forEach(item => {
          totalQuantity += item.quantity;
        });
        return totalQuantity;
      },
      totalPrice(state) {
        return state.items.reduce((acc, item) => {
          if (
            itemInactive(item, state.products) ||
            itemOutOfStock(item, state.products)
          ) {
            return acc;
          }

          return acc + item.total_price;
        }, 0);
      },
      cartDialogueShown(state) {
        return state.cartDialogueShown;
      },
      showCartPopover(state) {
        return !!state.newCartEntries.length;
      },
      newCartEntriesTotalQuantity(state) {
        return state.newCartEntries.reduce(
          (totalQuantity, entry) => totalQuantity + entry.quantity,
          0,
        );
      },
      productIds(state) {
        // returns product id from cart items as an array
        return state.items.reduce(
          (acc, cartItem) => acc.concat(getProductId(cartItem)),
          [],
        );
      },
      products(state) {
        return state.products;
      },
    },
    mutations: {
      updateId(state, val) {
        if (!val) {
          return;
        }
        state.id = val;
      },
      updateUserId(state, val) {
        if (!val) {
          return;
        }
        state.userId = val;
      },
      updateItems(state, val) {
        if (!val || val.length === undefined) {
          return;
        }
        state.items = val;
      },
      pushNewCartEntries(state, { quantity, product }) {
        let entry = state.newCartEntries.find(o => o.product.id === product.id);

        if (entry) {
          entry.quantity = quantity;
        } else {
          const variants =
            state.items.find(o => o.stuff.product_id === product.id)?.stuff
              ?.details ?? [];
          entry = { quantity, product, variants };
          state.newCartEntries.push(entry);
        }
      },
      deleteFromNewCartEntries(state, { product }) {
        state.newCartEntries = state.newCartEntries.filter(
          o => o.product.id !== product.id,
        );
      },
      clearNewCartEntries(state) {
        state.newCartEntries = [];
      },
      updateIsUpdated(state, isUpdated) {
        state.isUpdated = isUpdated;
      },
      updateIsReady(state, isReady) {
        state.isReady = isReady;
      },
      setCartsPromise(state, promise) {
        state.cartsPromise = promise;
      },
      deleteProductFromCart(state, { itemIds }) {
        const toBeRemovedItemIndex = [];
        state.items.forEach((item, index) => {
          if (itemIds.includes(item.id)) toBeRemovedItemIndex.push(index);
        });

        toBeRemovedItemIndex.sort((a, b) => b - a);
        toBeRemovedItemIndex.forEach(index => {
          const productId = state.items[index].stuff.product_id;
          state.items.splice(index, 1);
          state.updatedProduct = { id: productId, quantity: 0 };
        });
      },
      updateCartItem(state, { item }) {
        const index = state.items.findIndex(i => i.id === item.id);
        const { product } = state.items[index];
        state.items[index] = item;
        state.items[index].product = product;
        state.updatedProduct = {
          id: item.stuff.product_id,
          quantity: item.quantity,
        };
      },
      toggleShowCartDialogue(state, inpShow) {
        if (typeof inpShow === 'undefined') {
          state.cartDialogueShown = !state.cartDialogueShown;
        } else state.cartDialogueShown = inpShow;
      },
      addProduct(state, { product }) {
        state.products[product.id] = product;
      },
    },
    actions: {
      async getCarts({ state, commit }) {
        commit('updateIsUpdated', false);

        const promise = cartClient.getCarts();

        commit('setCartsPromise', promise);

        const res = await promise;

        if (!(res.meta.http_status < 300 && res.data)) {
          throw new Error('something went wrong');
        }

        commit('updateId', res.data.id);
        commit('updateUserId', res.data.user_id);
        commit('updateItems', res.data.items);

        if (state.isReady) {
          commit('updateIsUpdated', true);
        }

        commit('updateIsReady', true);

        return res;
      },
      clearNewCartEntriesDebounced({ state, commit }) {
        state.clearNewCartEntriesDebounced =
          state.clearNewCartEntriesDebounced ||
          debounce(
            () => commit('clearNewCartEntries'),
            SHOW_NEW_ENTRY_DURATION,
          );
        state.clearNewCartEntriesDebounced();
      },
      async addOrUpdateProductToCart(
        { state, dispatch },
        { quantity, product, query = {}, headers, ...otherPayload },
      ) {
        const item = state.items.find(
          o => o.stuff && o.stuff.id && o.stuff.id === product.sku_id,
        );

        const cartPayload = {
          product,
          quantity,
          query,
          headers,
          ...otherPayload,
        };

        if (!item) return dispatch('addProductToCart', cartPayload);
        return new Promise((resolve, reject) => {
          dispatch('updateProductInCart', { itemId: item.id, ...cartPayload })
            .then(res => resolve(res))
            .catch(patchErr => {
              if (patchErr.response?.status !== 404) return reject(patchErr);
              return dispatch('addProductToCart', cartPayload);
            });
        });
      },
      async addProductToCart(
        { commit, dispatch },
        { quantity, product, query = {}, headers, ...otherPayload },
      ) {
        const res = await cartClient.addProductToCart({
          quantity,
          productId: product.id,
          productSkuId: product.sku_id,
          query,
          headers,
          ...otherPayload,
        });

        trackAddToCart(product, quantity);

        if (!(res.meta.http_status < 300 && res.data)) {
          throw new Error('something went wrong');
        }

        await dispatch('getCarts');

        commit('pushNewCartEntries', { quantity, product });
        dispatch('clearNewCartEntriesDebounced');
        dispatch('getProducts');
        return res;
      },
      async updateProductInCart(
        { state, dispatch, commit },
        { itemId, product, quantity, query = {}, headers, ...otherPayload },
      ) {
        return performActionOnCart(
          'updateProductInCart',
          { state, commit, dispatch },
          { itemId, product, quantity, query, headers, ...otherPayload },
        );
      },
      async deleteProductFromCart(
        { state, dispatch, commit },
        { itemId, product, query = {}, ...otherPayload },
      ) {
        return performActionOnCart(
          'deleteProductFromCart',
          { state, commit, dispatch },
          { itemId, product, query, ...otherPayload },
        );
      },
      deleteProductFromCartWithoutRequest({ commit }, { itemIds = [] }) {
        commit('deleteProductFromCart', { itemIds });
      },
      updateItemWithoutRequest({ commit }, { item }) {
        commit('updateCartItem', { item });
      },
      // gets product objects based on cart items
      // product object is needed for getting store name, item active status, etc
      async getProducts({ commit, getters }) {
        const { productIds } = getters;
        const productsAggregate = productsClient.getProductsAggregate(
          productIds,
        );

        Object.keys(productsAggregate).forEach(async productId => {
          if (!getters.products[productId]) {
            const { data } = await productsAggregate[productId]();

            commit('addProduct', { product: data });
          }
        });
      },
    },
  };
};
