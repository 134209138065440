export default ({ API }) => ({
  ...API.wrap({
    getAll() {
      return this.get('/_exclusive/info/toggle-features/collections', null);
    },
    getFeature(feature) {
      return this.get(`/_exclusive/info/toggle-features/?feature=${feature}`);
    },
  }),
});
