import ProductsClient from '../apis/products';

export default context => {
  const productsClient = ProductsClient(context);

  let productPromise = null;

  return {
    namespaced: true,
    state: {
      product: null,
      isLoading: false,
    },
    actions: {
      getProduct({ state }, { id, force = false }) {
        if (productPromise && !force) {
          return productPromise;
        }

        state.isLoading = true;

        productPromise = productsClient.getProduct(id);

        return productPromise
          .then(res => {
            state.product = res.data;
            return res;
          })
          .finally(() => {
            state.isLoading = false;
          });
      },
    },
  };
};
