class BranchIO {
  constructor() {
    this.name = 'branch';
  }

  static loadSDK() {
    window.addEventListener('load', () => {
      window.dataLayer.push({ event: 'loadSDK.branch' });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get event() {
    // BranchIO currently has no events
    return {};
  }
}

export default BranchIO;
