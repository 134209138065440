import Tracker from '@bukalapak/tracker-js/dist/tracker.v0.2.5';

function viewport() {
  return `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`;
}

// set mobile homepage after visit referrer to page on which back/forward button is pressed
// https://tutuplapak.atlassian.net/browse/MARS-5322
export function setMobileAfterVisitHomepageReferrer(referrer) {
  const referrerUrl = referrer ? new URL(referrer) : null;
  const isHomepageAfterVisit = referrerUrl && referrerUrl.pathname === '/';
  const perfEntries = performance.getEntriesByType('navigation');
  const navigationType =
    perfEntries.length && perfEntries[0] && perfEntries[0].type;
  const lastVisitedUrl =
    sessionStorage.getItem('last_visited_url') || document.referrer;

  return isHomepageAfterVisit && navigationType === 'back_forward'
    ? lastVisitedUrl
    : document.referrer;
}

function trackerInit() {
  const hostname = window.location.hostname.split('.')[0];
  const platform = hostname === 'm' ? 'mweb' : 'desktop';
  Tracker.config({
    baseUrl: new URL(window.APP.internalTrackerUrl).origin,
    dev: process.env.NODE_ENV !== 'production',
    platform,
  });

  // Make it accessible for microFE
  window.Tracker = Tracker;

  // Legacy tracker_promise for outdated async tracker mechanism.
  // Might still be used in microFE
  window.TRACKER_P = Promise.resolve(Tracker);
}

function pageviews(USER) {
  window.document.addEventListener('DOMContentLoaded', () => {
    const hostname = window.location.hostname.split('.')[0];
    let { referrer } = document;
    if (hostname === 'm') {
      referrer = setMobileAfterVisitHomepageReferrer(referrer);
    }
    sessionStorage.removeItem('last_visited_url');

    const data = {
      evn: 'pageviews',
      td_host: window.location.hostname,
      td_path: window.location.pathname,
      td_referrer: referrer,
      td_title: document.title,
      td_url: window.location.href,
      td_viewport: viewport(),
      ui: USER.id36,
    };
    Tracker.event(data);

    // header
    const headerClickLogin = {
      evn: 'header_click_login',
      ui: USER.id36,
    };
    const headerClickRegister = {
      ...headerClickLogin,
      evn: 'header_click_register',
    };
    const buttonLogin = document.getElementById('js-track-login');
    const buttonRegister = document.getElementById('js-track-register');
    if (buttonLogin) {
      buttonLogin.addEventListener('click', () => {
        Tracker.event(headerClickLogin);
      });
    }
    if (buttonRegister) {
      document
        .getElementById('js-track-register')
        .addEventListener('click', () => {
          Tracker.event(headerClickRegister);
        });
    }
  });
}

function run({ USER }) {
  trackerInit();

  // internal tracker for all pages
  if (process.env.NODE_ENV === 'production') {
    pageviews(USER);
  }
}

export default {
  run,
};
