export default () => ({
  namespaced: true,
  state: {
    keyword: '',
    isFocus: false,
    isDeleteMode: false,
  },
  mutations: {
    updateKeyword(state, val) {
      state.keyword = val;
    },
    updateIsFocus(state, val) {
      state.isFocus = val;
    },
    toggleIsDeleteMode(state, val) {
      state.isDeleteMode = val !== undefined ? val : !state.isDeleteMode;
    },
  },
});
