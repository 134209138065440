export default ({ API }) => {
  const BASE_QUERY_PARAMS = {
    platform: 'onsite_web',
  };

  return {
    ...API.wrap({
      markAllAsRead() {
        return this.patch('/notifications/messages/all-read', 'user', {});
      },
      markAsRead(id) {
        return this.patch(`/notifications/messages/${id}/status`, 'user', {});
      },
    }),
    ...API.wrapAggregate({
      getUnreadCount() {
        return this.get('/notifications/messages/unread-count', 'user', { params: { ...BASE_QUERY_PARAMS } });
      },
      getUpdateItems(query = {}) {
        return this.get('/notifications/messages', 'user', {
          params: { ...query, ...BASE_QUERY_PARAMS, type: 'updates', expired_last: true },
        });
      },
      getPromotionItems(query = {}) {
        return this.get('/notifications/messages', 'user', {
          params: { ...query, ...BASE_QUERY_PARAMS, type: 'promotion', expired_last: true },
        });
      },
    }),
  };
};
