const messages = {
  you_login: {
    message: `Kamu telah login sebagai ${window.USER?.name || ''}`,
  },
  admin_expired: {
    message: 'Password admin kamu sudah expired. Mohon perbarui password kamu.',
  },
  user_already_verified: {
    message: 'Kamu sudah pernah melakukan verifikasi email.',
  },
  invalid_verification: {
    message: 'Link tidak dapat ditemukan.',
    isError: true,
  },
  cancel_email_verification_invalid: {
    message: 'Link sudah kedaluwarsa. Untuk info lanjut, hubungi BukaBantuan.',
    isError: true,
  },
  cancel_email_verification_failed: {
    message:
      'Verifikasi email tidak dibatalkan. Kamu dapat login, berbelanja, dan berjualan dengan email ini.',
  },
  streaming_voucher_inactive: {
    message:
      'Maaf, saat ini Fitur Voucher Streaming tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  game_voucher_inactive: {
    message:
      'Maaf, saat ini Fitur Voucher Game tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  digital_money_inactive: {
    message:
      'Maaf, saat ini Fitur Uang Elektronik tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  electricity_prepaid_inactive: {
    message:
      'Maaf, saat ini Fitur Token Listrik tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  phone_credit_prepaid_inactive: {
    message:
      'Maaf, saat ini Fitur Pulsa Prabayar tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  data_plan_inactive: {
    message:
      'Maaf, saat ini Fitur Paket Data tidak dapat diakses. Mohon coba beberapa saat lagi.',
  },
  pdam_inactive: {
    message:
      'Maaf, saat ini Fitur Air PDAM tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  bpjs_kesehatan_inactive: {
    message:
      'Maaf, saat ini Fitur BPJS Kesehatan tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  bpjs_ketenagakerjaan_inactive: {
    message:
      'Maaf, saat ini Fitur BPJS Ketenagakerjaan tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  credit_card_bill_inactive: {
    message:
      'Maaf, saat ini Fitur Tagihan Kartu Kredit tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  multifinance_inactive: {
    message:
      'Maaf, saat ini Fitur Angsuran Kredit tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  cable_tv_inactive: {
    message:
      'Maaf, saat ini Fitur TV Kabel & Internet tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  telkom_postpaid_inactive: {
    message:
      'Maaf, saat ini Fitur Tagihan Telkom tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  addon_indihome_inactive: {
    message:
      'Maaf, saat ini Fitur Addon Indihome tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  electricity_non_bill_inactive: {
    message:
      'Maaf, saat ini Fitur Listrik Non Taglis tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  electricity_postpaid_inactive: {
    message:
      'Maaf, saat ini Fitur Listrik Pascabayar tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  apply_cc_inactive: {
    message:
      'Maaf, saat ini Fitur Ajukan Kartu Kredit tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  insurance_bill_inactive: {
    message:
      'Maaf, saat ini Fitur Premi Asuransi tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  add_email_sent: {
    message: 'Cek email kamu untuk melakukan verifikasi email.',
  },
  change_email_sent: {
    message:
      'Cek email baru kamu untuk melakukan konfirmasi penggantian email.',
  },
  verify_phone_success: {
    message: 'Nomor handphone kamu berhasil diverifikasi.',
  },
  change_phone_success: {
    message: 'Nomor handphone kamu berhasil diubah.',
  },
  reset_password_email_sent: {
    message:
      'Instruksi untuk melakukan reset password sedang dikirimkan. Silakan cek e-mail kamu.',
  },
  staff_confirmation_failed: {
    message:
      'Konfirmasi karyawan gagal. Untuk info lanjut, hubungi BukaBantuan.',
    isError: true,
  },
  staff_not_logged_in: {
    message: 'Silakan login menggunakan akun karyawan terlebih dahulu.',
    isError: true,
  },
  staff_confirmation_invalid_token: {
    message: 'Konfirmasi aktivasi karyawan gagal, link tidak valid.',
    isError: true,
  },
  staff_confirmation_expired_token: {
    message:
      'Konfirmasi aktivasi karyawan gagal, link sudah expired. Silakan cek kembali e-mail kamu.',
    isError: true,
  },
  staff_confirmation_success: {
    message: 'Konfirmasi karyawan berhasil.',
  },
  not_registered_partner: {
    message:
      'You have not been registered as Partner in Bukalapak, please contact your PIC.',
    isError: true,
  },
  zakat_inactive: {
    message:
      'Maaf, saat ini Fitur Zakat tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  esamsat_inactive: {
    message:
      'Maaf, saat ini Fitur E-Samsat tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  signal_inactive: {
    message:
      'Maaf, saat ini Fitur SIGNAL tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  government_revenue_inactive: {
    message:
      'Maaf, saat ini Fitur Penerimaan Negara (BukaPajak) tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  property_tax_inactive: {
    message:
      'Maaf, saat ini Fitur Pajak Daerah tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  change_email_invalid_link: {
    message:
      'Link konfirmasi Anda tidak valid.',
    isError: true,
  },
  change_email_success: {
    message:
      'Pergantian email sukses',
  },
};

export default messages;
