export default ({ API, BROWSER_ID, IDENTITY }) => {
  const identity = BROWSER_ID || IDENTITY;
  const defaultQuery = {
    ...(identity
      ? {
          identity,
        }
      : {}),
  };

  return {
    ...API.wrap({
      addProductToCart({
        quantity,
        productId,
        productSkuId,
        query = {},
        headers = {},
        ...otherPayload
      }) {
        return this.post('/carts/items', 'user', {
          params: {
            ...defaultQuery,
            ...query,
          },
          data: {
            quantity,
            product_id: productId,
            product_sku_id: productSkuId,
            ...otherPayload,
          },
          headers,
        });
      },
      updateProductInCart({ itemId, quantity, query = {}, headers = {} }) {
        return this.patch(`/carts/items/${itemId}`, 'user', {
          params: {
            ...defaultQuery,
            ...query,
          },
          data: {
            quantity,
          },
          headers,
        });
      },
      deleteProductFromCart({ itemId, query = {}, headers = {} }) {
        return this.delete(`/carts/items/${itemId}`, 'user', {
          params: {
            ...defaultQuery,
            ...query,
          },
          headers,
        });
      },
    }),
    ...API.wrapAggregate({
      getCarts() {
        return this.get('/carts', 'user', {
          params: {
            ...defaultQuery,
          },
        });
      },
    }),
  };
};
