export default ({ API }) => ({
  ...API.wrap({
    getMyProducts({ params = {} } = {}) {
      return this.get('/stores/me/products', null, { params });
    },
    getProduct(id) {
      return this.get(`/products/${id}`);
    },
  }),
  getProductsAggregate(productIds = []) {
    const requests = productIds.reduce((acc, productId) => {
      const productUrl = `/products/${productId}`;

      acc[productId] = function() {
        return this.get(productUrl);
      };

      return acc;
    }, {});

    return API.wrapAggregate(requests);
  },
});
