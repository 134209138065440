import initialStore from './driver/initial-store-desktop';
import internalServiceDriver from './driver/internal-service';
import internalTrackerDriver from './driver/internal-tracker';
import siburungTracker from './driver/siburungTracker';
import snackbarParam from './driver/snackbar-param';
import externalTrackerBridge from './driver/external-tracker-bridge';
import urlDataReceiverDriver from './driver/url-data-receiver';
import { getCookie } from './modules/cookie';

const identity = getCookie('identity');
const browserId = getCookie('browser_id');

window.ENGINE.boot({
  platform: 'desktop',
  APP: window.APP,
  USER: window.USER,
  IDENTITY: identity || window.IDENTITY,
  BROWSER_ID: browserId,
  STORE: window.STORE,
  Vue: window.Vue,
  Vuex: window.Vuex,
  axios: window.axios,
  drivers: [
    externalTrackerBridge,
    siburungTracker,
    initialStore,
    internalServiceDriver,
    internalTrackerDriver,
    snackbarParam,
    urlDataReceiverDriver,
  ],
});
