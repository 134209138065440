export default () => ({
  namespaced: true,
  state: {
    searchUrl: '',
  },
  mutations: {
    updateSearchUrl(state, val) {
      state.searchUrl = val;
    },
  },
});
