const OTP_KEY_RULE = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const VESTA_KEY = {
  EXP_DATE: 'vesta_expiration_date',
  SESSION_ID: 'vesta_websession_id',
  ORG_ID: 'vesta_org_id',
};

function jsonParser(json) {
  try {
    return JSON.parse(json);
  } catch (ex) {
    return null;
  }
}

function decodeOtpKey(encodedData) {
  try {
    const decodedData = window.atob(encodedData);
    const data = jsonParser(decodedData);
    if (!data) return '';
    return data.otpKey || '';
  } catch (ex) {
    return null;
  }
}

function decodeVestaData(encodedData) {
  try {
    const decodedData = window.atob(encodedData);
    const data = jsonParser(decodedData);
    if (!data) return '';
    return data.vestaData || '';
  } catch (ex) {
    return null;
  }
}

function run() {
  const params = new URLSearchParams(window.location.search);
  const qParamsData = params.get('q');
  if (!qParamsData) return;
  const otpKey = decodeOtpKey(qParamsData);
  const vestaData = decodeVestaData(qParamsData);

  if (otpKey && OTP_KEY_RULE.test(otpKey)) {
    window.localStorage.setItem('otp_key', otpKey);
  }

  if (vestaData?.[VESTA_KEY.EXP_DATE]) {
    window.localStorage.setItem(
      VESTA_KEY.EXP_DATE,
      vestaData[VESTA_KEY.EXP_DATE],
    );
  }

  if (vestaData?.[VESTA_KEY.SESSION_ID]) {
    window.localStorage.setItem(
      VESTA_KEY.SESSION_ID,
      vestaData[VESTA_KEY.SESSION_ID],
    );
  }

  if (vestaData?.[VESTA_KEY.ORG_ID]) {
    window.localStorage.setItem(VESTA_KEY.ORG_ID, vestaData[VESTA_KEY.ORG_ID]);
  }
}

export default {
  run,
};
