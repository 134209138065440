import cartStore from '../desktop/stores/cart';
import categoryStore from '../desktop/stores/category';
import chatStore from '../desktop/stores/chat';
import featureToggleStore from '../desktop/stores/feature-toggle';
import notificationStore from '../desktop/stores/notification';
import pdpStore from '../desktop/stores/pdp';
import productExplorerStore from '../desktop/stores/productExplorer';
import snackbarStore from '../desktop/stores/snackbar';
import transactionStore from '../desktop/stores/transaction';
import walletStore from '../desktop/stores/wallet';
import whiteboxStore from '../desktop/stores/whitebox';

function run(context) {
  const { STORE } = context;
  STORE.registerModule('cart', cartStore(context));
  STORE.registerModule('category', categoryStore(context));
  STORE.registerModule('chat', chatStore(context));
  STORE.registerModule('feature-toggle', featureToggleStore(context));
  STORE.registerModule('notification', notificationStore(context));
  STORE.registerModule('pdp', pdpStore(context));
  STORE.registerModule('productExplorer', productExplorerStore(context));
  STORE.registerModule('snackbar', snackbarStore(context));
  STORE.registerModule('transaction', transactionStore(context));
  STORE.registerModule('wallet', walletStore(context));
  STORE.registerModule('whitebox', whiteboxStore(context));
}

export default {
  run,
};
