const GOOGLE_ANALYTICS = 'google-analytics';
const FACEBOOK_PIXEL = 'facebook-pixel';
const HOTJAR = 'hotjar';
const BRAZE = 'braze';
const BRANCH_IO = 'branch-io';

const DEFAULT_TRACKERS = [
  GOOGLE_ANALYTICS,
  FACEBOOK_PIXEL,
  { name: BRANCH_IO, webview: false, mobile: true, desktop: false },
];
const ALL_TRACKERS = [
  GOOGLE_ANALYTICS,
  FACEBOOK_PIXEL,
  HOTJAR,
  BRAZE,
  BRANCH_IO,
];
const DEFAULT_TRACKERS_EXCLUDE_BRANCH_IO = DEFAULT_TRACKERS.filter(
  t => !(typeof t === 'object' && t.name === BRANCH_IO),
);

module.exports = {
  GOOGLE_ANALYTICS,
  FACEBOOK_PIXEL,
  BRAZE,
  HOTJAR,
  DEFAULT_TRACKERS,
  BRANCH_IO,
  ALL_TRACKERS,
  DEFAULT_TRACKERS_EXCLUDE_BRANCH_IO,
};
