/* TODO: Delete unused after PRAMB-2546 deployed
   - hasNotification
   - UPDATE_HAS_NOTIFICATION
   - updateHasNotification
*/
export default () => {
  return {
    namespaced: true,
    state: {
      isShowTransactions: false,
      totalActiveTransactions: 0,
      hasNotification: false,
    },
    mutations: {
      UPDATE_SHOW_TRANSACTIONS(state, val) {
        state.isShowTransactions = val;
      },
      UPDATE_HAS_NOTIFICATION(state, val) {
        state.hasNotification = val;
      },
      UPDATE_TOTAL_ACTIVE_TRANSACTIONS(state, val) {
        state.totalActiveTransactions = val;
      },
    },
    actions: {
      showTransactions({ commit }, val) {
        commit('UPDATE_SHOW_TRANSACTIONS', val);
      },
      updateHasNotification({ commit }, val) {
        commit('UPDATE_HAS_NOTIFICATION', val);
      },
      updateTotalActiveTransactions({ commit }, val) {
        commit('UPDATE_TOTAL_ACTIVE_TRANSACTIONS', val);
      },
    },
  };
};
