class Hotjar {
  constructor() {
    this.name = 'hotjar';
  }

  static loadSDK() {
    window.addEventListener('DOMContentLoaded', () => {
      window.dataLayer.push({ event: 'loadSDK.hotjar' });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get event() {
    // Hotjar currently has no events
    return {};
  }
}

export default Hotjar;
