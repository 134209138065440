import { waitUntil, TimeoutError } from 'async-wait-until';

const send = async (...args) => {
  try {
    await waitUntil(() => !!window.fbq, {
      timeout: 10000,
      intervalBetweenAttempts: 100,
    });

    window.fbq(...args);
  } catch (error) {
    if (error instanceof TimeoutError) {
      // eslint-disable-next-line no-console
      console.error('Facebook Pixel fbq not found, please check GTM');
    }
  }
};

const TRACK_SINGLE = 'trackSingle';
const generalAttribute = {
  content_type: 'product',
  currency: 'IDR',
};
const generateContent = (product, quantity = 1) => {
  return {
    quantity,
    id: product.id,
    item_price: product.price,
  };
};
const generateProductParams = (product, quantity = 1) => {
  return {
    contents: [generateContent(product, quantity)],
    content_name: product.name,
    value: product.price * quantity,
    content_category: product.category.structure[0] || '-',
    fb_product_category: product.category.structure[0] || '-',
    fb_product_subcategory_1: product.category.structure[1] || '-',
    fb_product_subcategory_2: product.category.structure[2] || '-',
    fb_seller_id: product.store.id.toString(36),
    fb_product_brand: product.specs.brand || '-',
    fb_product_sku: product.sku_id || '-',
  };
};
const generalProductAttributeMany = (products = [], quantity = []) => {
  const facebookProduct = {
    contents: [],
    content_name: [],
    value: 0,
    content_category: [],
    fb_product_price: [],
    fb_product_category: [],
    fb_product_subcategory_1: [],
    fb_product_subcategory_2: [],
    fb_seller_id: [],
    fb_product_brand: [],
    fb_product_sku: [],
  };
  products.forEach((product, i) => {
    facebookProduct.contents.push(generateContent(product, quantity[i]));
    facebookProduct.content_name.push(product.name);
    facebookProduct.value += product.price;
    facebookProduct.content_category.push(product.category.structure[0] || '-');
    facebookProduct.fb_product_price.push(product.price);
    facebookProduct.fb_product_category.push(
      product.category.structure[0] || '-',
    );
    facebookProduct.fb_product_subcategory_1.push(
      product.category.structure[1] || '-',
    );
    facebookProduct.fb_product_subcategory_2.push(
      product.category.structure[2] || '-',
    );
    facebookProduct.fb_seller_id.push(product.store.id.toString(36));
    facebookProduct.fb_product_brand.push(product.specs.brand || '-');
    facebookProduct.fb_product_sku.push(product.sku_id || '-');
  });

  return facebookProduct;
};

const generalInvoice = (carts = [], invoice, hasOrderId = false) => {
  const products = carts.map(cart => cart.product);
  const productQuantities = carts.map(cart => cart.quantity);

  return {
    ...generalProductAttributeMany(products, productQuantities),
    value: carts.reduce(
      (sum, cart) => sum + cart.quantity * cart.product.price,
      0,
    ),
    fb_product_quantity: productQuantities,
    fb_coupon: invoice.voucher || '-',
    fb_payment_option: invoice.payment || '-',
    fb_shipping_option: invoice.shipping || '-',
    num_items: productQuantities.reduce((sum, q) => sum + q, 0),
    order_id: !hasOrderId ? null : invoice.id || '-',
  };
};

class Facebook {
  constructor(user, id) {
    this.user = user;
    this.id = id;
    this.name = 'facebook';
  }

  initiateCheckout(carts = [], invoice) {
    send(TRACK_SINGLE, this.id, 'InitiateCheckout', {
      ...generalAttribute,
      ...generalInvoice(carts, invoice),
    });
  }

  get event() {
    return {
      pageView: () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'facebook.PageView',
        });
      },
      addToCart: cart => {
        const { product } = cart;

        send(TRACK_SINGLE, this.id, 'AddToCart', {
          ...generalAttribute,
          ...generateProductParams(product, cart.quantity),
          fb_product_quantity: cart.quantity,
        });
      },
      productDetail: product => {
        const params = {
          ...generalAttribute,
          ...generateProductParams(product),
        };
        send(TRACK_SINGLE, this.id, 'ViewContent', params);
      },
      search: (keyword, products = []) => {
        const params = {
          ...generalAttribute,
          ...generalProductAttributeMany(products),
          fb_product_brand: null,
          fb_product_sku: null,
          fb_product_price: null,
          fb_product_category: null,
          search_string: keyword || '-',
          success: products.length ? 1 : 0,
        };

        send(TRACK_SINGLE, this.id, 'Search', params);
      },
      checkout: (carts, invoice, step) => {
        if (step !== 3) return;

        this.initiateCheckout(carts, invoice);
      },
      purchase: (carts, invoice) => {
        send(TRACK_SINGLE, this.id, 'Purchase', {
          ...generalAttribute,
          ...generalInvoice(carts, invoice, true),
        });
      },
      custom: (name, data) => {
        send(TRACK_SINGLE, this.id, name, data);
      },
    };
  }
}

export default Facebook;
