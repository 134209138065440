import ChatClient from '../apis/chat';

export default context => {
  const chatClient = ChatClient(context);

  return {
    namespaced: true,
    state: {
      unreadRooms: 0,
    },
    mutations: {
      updateUnreadRooms(state, val) {
        state.unreadRooms = val;
      },
    },
    actions: {
      async getUnreadRooms({ commit }) {
        const { unread_rooms: unreadCount } = (await chatClient.getUnreadRooms()).data;
        commit('updateUnreadRooms', unreadCount);
      },
    },
  };
};
