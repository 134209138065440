import { waitUntil, TimeoutError } from 'async-wait-until';

const sendCustomEvent = async (...args) => {
  try {
    await waitUntil(() => !!window.appboy, {
      timeout: 10000,
      intervalBetweenAttempts: 100,
    });

    window.appboy.logCustomEvent(...args);
  } catch (error) {
    if (error instanceof TimeoutError) {
      // eslint-disable-next-line no-console
      console.error('Braze appboy not found, please check GTM');
    }
  }
};

class Braze {
  constructor(user) {
    this.user = user;
    this.name = 'braze';
  }

  static loadSDK() {
    window.addEventListener('load', () => {
      // unprioritize braze over other SDK loading,
      // such as Google and Facebook that more important
      window.dataLayer.push({ event: 'loadSDK.braze' });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get event() {
    return {
      custom: (name, data) => {
        sendCustomEvent(name, data);
      },
    };
  }
}

export default Braze;
