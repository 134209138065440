import FeaturesToggleClient from '../apis/features-toggle';

export default context => {
  const featuresToggleClient = FeaturesToggleClient(context);

  return {
    namespaced: true,
    state: {
      features: {},
    },
    getters: {
      getFeatureToggleByName: state => feature => state.features[feature],
    },
    mutations: {
      updateFeatureStatus(state, payload) {
        state.features[payload.feature] = payload.status;
      },
    },
    actions: {
      // request feature toggle status and update state
      async updateFeatureStatus({ commit }, feature) {
        const { data } = await featuresToggleClient.getFeature(feature);

        commit('updateFeatureStatus', { feature, status: data.status });
      },
    },
  };
};
