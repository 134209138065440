const messages = {
  reset_password_email_sent: {
    message:
      'Instruksi untuk melakukan reset password sedang dikirimkan. Silakan cek e-mail kamu.',
  },
  bpjs_kesehatan_inactive: {
    message:
      'Maaf, saat ini Fitur BPJS Kesehatan tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  bpjs_ketenagakerjaan_inactive: {
    message:
      'Maaf, saat ini Fitur BPJS Ketenagakerjaan tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  cable_tv_inactive: {
    message:
      'Maaf, saat ini Fitur TV Kabel & Internet tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  credit_card_bill_inactive: {
    message:
      'Maaf, saat ini Fitur Tagihan Kartu Kredit tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  electricity_non_bill_inactive: {
    message:
      'Maaf, saat ini Fitur PLN Non-Taglis tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  electricity_postpaid_inactive: {
    message:
      'Maaf, saat ini Fitur Listrik Pascabayar tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  multifinance_inactive: {
    message:
      'Maaf, saat ini Fitur Angsuran Kredit tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  pdam_inactive: {
    message:
      'Maaf, saat ini Fitur Air PDAM tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  telkom_postpaid_inactive: {
    message:
      'Maaf, saat ini Fitur Tagihan Telkom tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  zakat_inactive: {
    message:
      'Maaf, saat ini Fitur Zakat tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  esamsat_inactive: {
    message:
      'Maaf, saat ini Fitur E-Samsat tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  government_revenue_inactive: {
    message:
      'Maaf, saat ini Fitur Penerimaan Negara (BukaPajak) tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  bukadonasi_inactive: {
    message:
      'Maaf, saat ini Fitur Bukadonasi tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  property_tax_inactive: {
    message:
      'Maaf, saat ini Fitur Pajak Daerah tidak dapat diakses. Mohon coba beberapa saat lagi.',
    isError: true,
  },
  change_email_invalid_link: {
    message:
      'Link konfirmasi Anda tidak valid.',
    isError: true,
  },
  change_email_success: {
    message:
      'Pergantian email sukses',
  },
};

export default messages;
