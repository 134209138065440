import { trackRedirection, trackCampaign, trackVisit, getAppIntent } from './tracker';

function siburung() {
  const loweredUA = navigator.userAgent.toLowerCase();
  const isNotWebview = !['blandroid', 'blios'].some(a => loweredUA.includes(a));

  if (window.location.href.includes('bledm')) {
    if (getAppIntent()) {
      trackRedirection();
    } else if (isNotWebview) {
      trackCampaign();
      trackVisit();
    }
  } else if (isNotWebview) {
    trackVisit();
  }
}

export default siburung;
