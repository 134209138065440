import md5 from 'md5';
import { sha256 } from 'js-sha256';

const generateUser = user => {
  return {
    em: sha256(user.email),
    userID: user.id,
    userType: user.type,
  };
};

const generalProductAttributeSingle = product => {
  return {
    id: product.id,
    price: product.price,
    name: product.name,
    category: product.category.structure[0],
    dimension20: product.store.id.toString(36),
    dimension24: product.category.structure[1] || '',
    dimension25: product.category.structure[2] || '',
    sellerName: product.store.name,
    sellerId: product.store.id,
  };
};

const generateAddToCartPayload = cart => {
  return {
    ...generalProductAttributeSingle(cart.product),
    quantity: cart.quantity,
  };
};
const googleEcommercePurchase = (carts, invoice) => {
  return {
    paymentMethod: invoice.payment,
    voucherAmount: invoice.voucherAmount,
    ecommerce: {
      purchase: {
        actionField: {
          id: invoice.id,
          revenue: invoice.subtotal,
          shipping: invoice.shipping,
          coupon: invoice.voucher,
        },
        products: carts.map(cart => ({
          ...generalProductAttributeSingle(cart.product),
          quantity: cart.quantity,
        })),
      },
    },
  };
};
const checkoutOption = (step, invoice) => {
  if (step === 2) return invoice.shipping;
  if (step === 3) return invoice.payment;
  return '-';
};
const googleEcommerceCheckout = (carts, invoice, step) => {
  return {
    paymentMethod: invoice.payment,
    voucherAmount: invoice.voucherAmount,
    ecommerce: {
      checkout: {
        actionField: {
          step,
          revenue: invoice.subtotal,
          option: checkoutOption(step, invoice),
          coupon: invoice.voucher,
        },
        products: carts.map(cart => ({
          ...generalProductAttributeSingle(cart.product),
          quantity: cart.quantity,
        })),
      },
    },
  };
};
const getCheckoutEvent = step => {
  const steps = ['cart', 'checkoutShipping', 'checkoutPayment'];

  return steps[step - 1] || '';
};

class Google {
  constructor(user) {
    this.user = user;
    this.name = 'google';
  }

  // eslint-disable-next-line class-methods-use-this
  get event() {
    return {
      pageView: ({ pageData }) => {
        window.dataLayer.push({
          event: 'ga.pageview',
          ...pageData,
        });
      },
      productDetail: product => {
        this.event.eeProductDetail(product);
        this.event.remarketing([product]);
      },
      addToCart: cart => {
        this.event.eeAddToCart(cart);
      },
      search: (keyword, products) => {
        this.event.remarketing(products);
        this.event.eeSearch(keyword, products);
      },
      purchase: (carts, products) => {
        this.event.eePurchase(carts, products);
      },
      checkout: (carts, invoice, step = 1) => {
        this.event.eeCheckout(carts, invoice, step);
      },
      custom: (eventName, data) => {
        window.dataLayer.push({
          ...generateUser(this.user),
          ...data,
          event: eventName,
        });
      },
      remarketing: (products = []) => {
        const firstFourProducts = products.slice(0, 3);
        const hashedEmail = md5(this.user.email);

        window.dataLayer.push({
          event: 'dynRmktParamsReady',
          google_tag_params: {
            ecomm_prodid: firstFourProducts.map(product => product.id),
            ecomm_pagetype: 'category',
            ecomm_totalvalue: firstFourProducts.reduce(
              (total, product) => total + product.price,
              0,
            ),
            email: hashedEmail,
          },
        });
      },
      eeProductDetail: product => {
        window.dataLayer.push({
          event: 'productDetails',
          ...generateUser(this.user),
          ecommerce: {
            detail: {
              products: [generalProductAttributeSingle(product)],
            },
          },
        });
      },
      eeAddToCart: cart => {
        window.dataLayer.push({
          ...generateUser(this.user),
          event: 'addToCart',
          ecommerce: {
            add: {
              products: [generateAddToCartPayload(cart)],
            },
          },
        });
      },
      eeSearch: (keyword, products) => {
        window.dataLayer.push({
          ...generateUser(this.user),
          event: 'productImpression',
          keyword,
          ecommerce: {
            currencyCode: 'IDR',
            impressions: products.map((product, i) => ({
              ...generalProductAttributeSingle(product),
              position: i + 1,
            })),
          },
        });
      },
      eePurchase: (carts, invoice) => {
        window.dataLayer.push({
          ...generateUser(this.user),
          ...googleEcommercePurchase(carts, invoice),
          event: 'ecommercePurchase',
        });
      },
      eeCheckout: (carts, invoice, step) => {
        window.dataLayer.push({
          ...generateUser(this.user),
          ...googleEcommerceCheckout(carts, invoice, step),
          event: getCheckoutEvent(step),
        });
      },
    };
  }
}

export default Google;
