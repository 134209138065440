import { sha256 } from 'js-sha256';

import Facebook from './facebook';
import Google from './google';
import Hotjar from './hotjar';
import Braze from './braze';
import BranchIO from './branch-io';

import {
  GOOGLE_ANALYTICS,
  FACEBOOK_PIXEL,
  HOTJAR,
  BRAZE,
  BRANCH_IO,
} from '../../types/external-trackers';

class ExternalTracker {
  constructor(USER, { externalTrackerToLoads, isMobile, isWebview } = {}) {
    const formattedUser = ExternalTracker.formatUser(USER);

    let platform = isMobile ? 'mobile' : 'desktop';
    if (isWebview) {
      platform = 'webview';
    }
    this.user = formattedUser;

    this.init();

    this.trackerList = [];
    this.tracker = {};

    const isTrackerConfigMatch = trackerName => {
      if (externalTrackerToLoads.includes(trackerName)) {
        return true;
      }

      const trackerConfig = externalTrackerToLoads.find(
        t => typeof t === 'object' && t.name === trackerName,
      );

      return trackerConfig && trackerConfig[platform];
    };

    if (isTrackerConfigMatch(GOOGLE_ANALYTICS)) {
      const google = new Google(formattedUser);
      this.trackerList.push(google);
      this.tracker.google = google;

      // SDK initiated together with PageView event
    }

    if (isTrackerConfigMatch(FACEBOOK_PIXEL)) {
      const facebook = new Facebook(
        formattedUser,
        process.env.FACEBOOK_PIXEL_ID,
      );
      this.trackerList.push(facebook);
      this.tracker.facebook = facebook;

      // SDK initiated together with PageView event
    }
    if (isTrackerConfigMatch(HOTJAR)) {
      Hotjar.loadSDK();
    }
    if (isTrackerConfigMatch(BRAZE)) {
      const braze = new Braze(formattedUser);
      this.trackerList.push(braze);
      this.tracker.braze = braze;

      Braze.loadSDK();
    }
    if (isTrackerConfigMatch(BRANCH_IO)) {
      if (
        // branch-io is not dismissed yet
        !window.localStorage.getItem('BRANCH_WEBSDK_KEYjourneyDismissals')
      ) {
        BranchIO.loadSDK();
      }
    }
  }

  init() {
    window.dataLayer = window.dataLayer || [];

    this.addUserDataToDataLayer();
  }

  addUserDataToDataLayer() {
    window.dataLayer.push({
      event: 'userData',
      ...this.user,
      ...ExternalTracker.generateUserInfoForGoogle(this.user),
    });
  }

  static generateUserInfoForGoogle(user) {
    return {
      em: user.email ? sha256(user.email) : undefined,
      userID: user.id,
      userType: user.type,
    };
  }

  static formatUser(USER) {
    const {
      email,
      gender,
      phone,
      name = '',
      id36: id,
      birth_date: birthDate,
      o2o_agent: o2oAgent,
    } = USER;

    const fbGenderMap = {
      'Laki-laki': 'm',
      Perempuan: 'f',
    };
    const type = o2oAgent ? 'agen' : 'non-agen';
    const birthDateStringified = atob(birthDate || '');
    let firstName;
    let lastName;

    if (name.length > 0) {
      const nameArr = name.split(' ');
      [firstName] = nameArr;
      [lastName] = nameArr.length > 1 ? nameArr.slice(-1) : [];
    }

    return {
      id,
      firstName,
      lastName,
      email,
      phone: phone ? atob(phone) : undefined,
      gender,
      genderFBPixelFormat: fbGenderMap[gender],
      birthDate: birthDateStringified,
      birthDateFBPixelFormat: birthDateStringified
        ? birthDateStringified.replace(/-/g, '')
        : null,
      type,
      country: 'id',
    };
  }

  get track() {
    return {
      pageView: data => {
        this.trackerList.forEach(tracker => tracker.event.pageView?.(data));
      },
      productDetail: product => {
        this.trackerList.forEach(tracker =>
          tracker.event.productDetail?.(product),
        );
      },
      addToCart: cart => {
        this.trackerList.forEach(tracker => tracker.event.addToCart?.(cart));
      },
      search: (keyword, products) => {
        this.trackerList.forEach(tracker =>
          tracker.event.search?.(keyword, products),
        );
      },
      purchase: (carts = [], invoice) => {
        this.trackerList.forEach(tracker =>
          tracker.event.purchase?.(carts, invoice),
        );
      },
      checkout: (carts = [], invoice, step) => {
        this.trackerList.forEach(tracker =>
          tracker.event.checkout?.(carts, invoice, step),
        );
      },
      custom: (name, data) => {
        this.trackerList.forEach(tracker => tracker.event.custom?.(name, data));
      },
    };
  }
}

export default ExternalTracker;
