/* eslint-disable no-console */
import Cookie from 'js-cookie';

const cendraPath = '/track_external.json';
const referrerUrl = document.referrer;

const getDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let res = 'desktop_web';
  ['iphone', 'android'].forEach(d => {
    const webview =
      userAgent.includes('blios') || userAgent.includes('blandroid');
    if (userAgent.includes(d) && !webview) res = d;
  });
  return res;
};

const getParam = paramName => {
  let result = null;
  let tmp = [];
  const items = window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index += 1) {
    tmp = items[index].split('=');
    if (tmp[0] === paramName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

export const getAppIntent = () => {
  if (getDevice() === 'desktop_web') return false;

  const currentURL = new URL(window.location.href);
  currentURL.searchParams.set('redirectedfrom', getDevice());
  let redirectTo = currentURL.href;
  redirectTo = redirectTo.replace(currentURL.protocol, 'bukalapak:');

  if (getDevice() === 'iphone') {
    redirectTo = redirectTo.replace(`${currentURL.host}/`, '');
  }

  return redirectTo;
};

export const trackVisit = () => {
  const loggedIn = Cookie.get('track_login') && window.USER.id36;
  const payload = {
    client_id: Cookie.get('browser_id'),
    referrer_url: referrerUrl,
    landing_page_url: window.location.href,
    action: 'visit',
    identity: window.IDENTITY || '',
    user_id: window.USER.id36 || null,
  };
  if (loggedIn) {
    payload.logged_in = true;
    payload.action = 'login';
  }

  if (
    window.location.search.split('blca=').length === 1 &&
    !referrerUrl.includes(window.location.hostname)
  ) {
    window.axios.post(cendraPath, JSON.stringify(payload)).catch(error => {
      console.warn('error cendrawasih', error);
    });
  }
};

export const trackCampaign = () => {
  const bledm = getParam('bledm');
  const referrer = document.referrer && new URL(document.referrer);
  const {
    id36,
    agent: { platform },
  } = window.USER;
  const payload = {
    action: 'edmcampaign',
    campaign_name: bledm.split('-')[0],
    campaign_id: bledm,
    created_at: new Date().toISOString(),
    landing_page_url: window.location.href,
    user_id: id36 || null,
    referrer_domain: (referrer && referrer.origin) || '',
    referrer_path: (referrer && `${referrer.pathname}${referrer.search}`) || '',
    platform,
  };

  window.axios.post(cendraPath, JSON.stringify(payload)).catch(error => {
    console.warn('error cendrawasih', error);
  });
};

// export const trackRedirection = async () => {
//   const intent = getAppIntent();

//   try {
//     const payload = {
//       action: 'EDMREDIRECTION',
//       landing_page_url: intent,
//     };
//     await window.axios.post(cendraPath, JSON.stringify(payload));
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.error('siburung error', e);
//   } finally {
//     const isIphone = getDevice() === 'iphone';
//     const timeout = isIphone ? 3000 : 700;
//     // Redirect to intent if opened from android/ios
//     window.location.href = intent;
//     // Track as landed in web if not redirected within timeout value
//     window.setTimeout(() => {
//       trackCampaign();
//       trackVisit();
//     }, timeout);
//   }
// };

/**
 * Temporarily avoid using `async-await` until the babel updated to support it.
 */
export const trackRedirection = () => {
  return Promise.resolve()
    .then(() => {
      const intent = getAppIntent();
      const payload = {
        action: 'EDMREDIRECTION',
        landing_page_url: intent,
      };

      return window.axios.post(cendraPath, JSON.stringify(payload));
    })
    .catch(e => {
      console.error('siburung error', e);
    })
    .finally(() => {
      const intent = getAppIntent();
      const isIphone = getDevice() === 'iphone';
      const timeout = isIphone ? 3000 : 700;
      // Redirect to intent if opened from android/ios
      window.location.href = intent;
      // Track as landed in web if not redirected within timeout value
      window.setTimeout(() => {
        trackCampaign();
        trackVisit();
      }, timeout);
    });
};
