import ExternalTracker from './external-tracker';
import { DEFAULT_TRACKERS } from '../../types/external-trackers';

function run({ USER = {}, APP } = {}) {
  const {
    externalTrackerToLoads = DEFAULT_TRACKERS,
    isMobile,
    isWebview,
  } = APP;
  // Make it accessible for microFE
  const externalTracker = new ExternalTracker(USER, {
    externalTrackerToLoads,
    isMobile,
    isWebview,
  });
  window.externalTracker = externalTracker;

  document.addEventListener('DOMContentLoaded', () => {
    try {
      const dimension2 = window.USER.id ? 'User' : 'Guest';
      const pageData = { dimension2 };

      externalTracker.track.pageView({ pageData });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('tracker_pageview_fail', error);
    }
  });
}

export default {
  run,
};
