const cached = {};

function run(context) {
  const { platform } = context;

  const getService = async serviceName => {
    try {
      const { default: init } = await import(
        /* webpackChunkName: "svc-[request]" */
        `../${platform}/services/${serviceName}/index`
      );
      init(context);
    } catch (err) {
      // rethrow the error to make developers aware
      throw err;
    }
  };

  class InternalServices extends HTMLElement {
    connectedCallback() {
      const name = this.getAttribute('name');
      if (!cached[name]) {
        setImmediate(() => {
          getService(this.getAttribute('name'));
        });
      }

      cached[name] = true;
    }
  }

  window.customElements.define('internal-service', InternalServices);
}

export default {
  run,
};
