import Cookie from 'js-cookie';
import evtJs from '@bukalapak/external-visit-tracker-js';

function externalVisitTrackerJs() {
  if (!window) return;

  const cookieLoginName = 'track_login';
  const cookieDomain = process.env.WEB_COOKIE_DOMAIN;
  const loggedIn = Cookie.get(cookieLoginName) && window.USER.id36;
  const action = loggedIn ? 'login' : 'visit';

  evtJs.run({
    axios: window.axios,
    cookieDomain,
    action,
    afterSend: () => {
      if (Cookie.get(cookieLoginName))
        Cookie.remove(cookieLoginName, { path: '/', domain: cookieDomain });
    },
  });
}

export default externalVisitTrackerJs;
