import snackbarMessagesDesktop from './snackbar-messages-desktop';
import snackbarMessagesMobile from './snackbar-messages-mobile';

const SNACKBAR_PARAM = 'flash';
const SHOW_TIMER_IN_MS = 2000;

const flashKey = new URLSearchParams(window.location.search).get(
  SNACKBAR_PARAM,
);

function run({ APP }) {
  const { isMobile } = APP;

  const flashData = isMobile
    ? snackbarMessagesMobile[flashKey]
    : snackbarMessagesDesktop[flashKey];
  const storeAction = isMobile
    ? 'snackbarMobile/showMessage'
    : 'snackbar/showMessage';
  if (flashData) {
    const {
      actionText,
      active,
      hasLongAction,
      isError,
      message,
      action = () => {},
      close = () => {},
    } = flashData;

    setTimeout(() => {
      window.STORE.dispatch(storeAction, {
        actionText,
        active,
        hasLongAction,
        isError,
        message,
        action,
        close,
      });
    }, SHOW_TIMER_IN_MS);
  }
}

export default {
  run,
};
