import siburung from '../../modules/siburungTracker';
import externalVisitTrackerJs from '../../modules/externalVisitTrackerJs';

const run = async () => {
  // siburung 1.0
  siburung();

  // siburung 2.0
  externalVisitTrackerJs();
};

export default {
  run,
};
