import NotificationClient from '../apis/notification';

const LIMIT = 5;

export default context => {
  const notificationClient = NotificationClient(context);

  function performGetItems(type, { state, commit }) {
    if (state[type].isLoading) {
      return Promise.resolve([]);
    }

    const typeActionMap = {
      promotions: notificationClient.getPromotionItems,
      updates: notificationClient.getUpdateItems,
    };
    const query = {
      offset: state[type].meta.offset,
      limit: state[type].meta.limit,
    };

    commit('updateIsLoading', { type, isLoading: true });

    return typeActionMap[type](query)
      .then(res => {
        if (res.meta.http_status < 400 && res.data) {
          commit('incrementOffset', { type, amount: res.data.length || 0 });
          commit('addItems', { type, items: res.data });
        }
        commit('updateIsLoading', { type, isLoading: false });
      })
      .catch(() => {
        commit('updateIsLoading', { type, isLoading: false });
      });
  }

  return {
    namespaced: true,
    state: {
      unreadCount: 0,
      updates: {
        isLoading: false,
        items: [],
        meta: {
          limit: LIMIT,
          offset: 0,
        },
      },
      promotions: {
        isLoading: false,
        items: [],
        meta: {
          limit: LIMIT,
          offset: 0,
        },
      },
    },
    mutations: {
      updateUnreadCount(state, val) {
        state.unreadCount = val;
      },
      addItems(state, { type, items }) {
        items.forEach(item => {
          state[type].items.push(item);
        });
      },
      incrementOffset(state, { type, amount }) {
        state[type].meta.offset += amount;
      },
      updateIsLoading(state, { type, isLoading }) {
        state[type].isLoading = isLoading;
      },
      markItemAsRead(state, { type, id }) {
        const item = state[type].items.find(o => o.id === id);
        if (item) {
          if (!item.clicked_at) item.clicked_at = new Date().toISOString();
        }
      },
      markAllItemsAsRead(state) {
        const now = new Date().toISOString();
        state.promotions.items.forEach(item => {
          if (!item.clicked_at) item.clicked_at = now;
        });
        state.updates.items.forEach(item => {
          if (!item.clicked_at) item.clicked_at = now;
        });
      },
    },
    actions: {
      getUnreadCount({ commit }) {
        return notificationClient.getUnreadCount().then(res => {
          if (res.meta.http_status < 400 && res.data) {
            commit('updateUnreadCount', Number(res.data.unread_count || 0));
          }
        });
      },
      getUpdateItems({ state, commit }) {
        return performGetItems('updates', { state, commit });
      },
      getPromotionItems({ state, commit }) {
        return performGetItems('promotions', { state, commit });
      },
      markAsRead({ commit }, { type, id }) {
        return notificationClient.markAsRead(id).then(res => {
          if (res.meta.http_status < 400) {
            commit('markItemAsRead', { type, id });
          }
        });
      },
      markAllAsRead({ commit }) {
        return notificationClient.markAllAsRead().then(res => {
          if (res.meta.http_status < 400) {
            commit('markAllItemsAsRead');
          }
        });
      },
    },
  };
};
