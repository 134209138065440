import categoryInterface from '../apis/category';

export default context => {
  const categoryClient = categoryInterface(context);

  return {
    namespaced: true,
    state: {
      list: [],
      categoryBrand: {},
    },
    mutations: {
      updateList(state, newList) {
        state.list = newList;
      },
      updateCategoryBrand(state, brand) {
        state.categoryBrand = Object.assign({}, state.categoryBrand, brand);
      },
    },
    actions: {
      getCategories({ commit }) {
        return categoryClient.getCategories().then(res => {
          if (res.meta.http_status < 400 && res.data) {
            commit('updateList', res.data);
          }
        });
      },
      getCategoryBrand({ commit, state }, categoryId) {
        if (!categoryId || state.categoryBrand[categoryId]) {
          return Promise.resolve(state.categoryBrand[categoryId]);
        }

        return categoryClient.getCategoryBrand(categoryId).then(res => {
          if (res.meta.http_status < 400 && res.data) {
            commit('updateCategoryBrand', {
              [categoryId]: res.data,
            });

            return res.data;
          }

          return [];
        });
      },
    },
  };
};
